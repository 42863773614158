const urlPatternString = "^https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(:\\d{1,5}|.[a-zA-Z]{2,})?\\b([-a-zA-Z0-9@:%_+.~#?&/=()]*)$";

export function urlPattern() {
    return new RegExp(urlPatternString, "g");
}

export function youtubeUrlPattern() {
    // https://stackoverflow.com/a/27728417
    return new RegExp("^(?:https?:\/\/.*)(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*", "g");
}

function combinedRegex(listOfRegex: RegExp[], flag: string): RegExp {
    return new RegExp(
        listOfRegex
            .map((part: RegExp) => part.source)
            .join(""),
        flag);
}

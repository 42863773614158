export const INTRANET = "intranet";
export const INTRANET_UID = "intranetUid";
export const IS_ADMIN = "isAdmin";
export const COLORS = "COLORS";
export const LOGO_URL = "logoUrl";
export const INITIALIZED = "initialized";
export const FEATURE_PREVIEW_ACTIVE = "featurePreviewActive";
export const IS_FEATURE_ENABLED = "isFeatureEnabled";
export const SUBSCRIPTION_EXPIRATION_DATE = "subscriptionExpirationDate";
export const SUBSCRIPTION_DAYS_LEFT = "subscriptionDaysLeft";
export const SUBSCRIPTION_STATUS = "subscriptionStatus";
export const CONTACT_UIDS = "contactUids";
export const HAS_PERMISSIONS = "hasPermissions";

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyDYvJlboT0VebkwB6NSFtaM1ulmSgsJcho",
    authDomain: "linchpin-everest-beta.firebaseapp.com",
    databaseURL: "https://linchpin-everest-beta.firebaseio.com",
    projectId: "linchpin-everest-beta",
    storageBucket: "linchpin-everest-beta.appspot.com",
    messagingSenderId: "560518210722",
    appId: "1:560518210722:web:c39f70323d935e3ff3e3fb",
    measurementId: "G-57YE0WBJH3"
};
export const cloudRunDomainId = "vd6wyme5gq-ew.a.run.app";
export const firebaseCloudFunctionUrl = "https://europe-west1-linchpin-everest-beta.cloudfunctions.net";
export const hostUrl = "https://beta.linchpin.cloud";
export const firebaseCloudFunctionRegions = ["europe-west1"];
export const appleWebLoginEnabled = true;
export const ALGOLIA_ID = "Q9V1ZX5T0Z";
export const productFruitsProjectCode = "dwLNSp3U2ebG9sBD";
export const hubspot = {
    portalId: "8419861",
    formGuid: "59600219-7d38-4ef2-a2f4-fd91d389c7e1"
};
export const activatePerformanceStats = true;
export const reindexingLimit: number | undefined = undefined;
